<script>
export default {
  name: "FortytwocustomHead",
  props: {
    contextType: {
      type: String,
      required: true,
    },
    contextData: {
      type: Array,
      default: () => ([]),
    },
  },
  head() {
    let addScript = [];
    // let dataRecContext = '';
    // if (this.contextType == 'HOMEPAGE' || this.contextType == 'OTHER' || this.contextType == ''){
    //   dataRecContext = {type:this.contextType};
    // }else{
    //   dataRecContext = {type:this.contextType,data:this.contextData};
    // }
    if (process.server) {
      addScript = [
        {
          hid: 'dy-api-dynamic',
          type: 'text/javascript',
          src: `//cdn.dynamicyield.com/api/${process.env.VSF_DYNAMICYIELD_SECTIONID}/api_dynamic.js`,
        },
        {
          hid: 'dy-api-static',
          type: 'text/javascript',
          src: `//cdn.dynamicyield.com/api/${process.env.VSF_DYNAMICYIELD_SECTIONID}/api_static.js`,
        },
      ];
    }
    // const contextScript = (this.contextData !== '') ? {
    //     hid: 'dy-recommendation-context',
    //     innerHTML: `
    //         //<![CDATA[
    //         window.DY = window.DY || {};
    //         DY.recommendationContext = ${JSON.stringify(dataRecContext)};
    //         //]]>
    //     `,
    //     type: 'text/javascript',
    //     charset: 'utf-8',
    // } : null;

    return {
      script: [
        // ...(contextScript ? [contextScript] : []),
        ...addScript,
      ],
      // __dangerouslyDisableSanitizersByTagID: contextScript ? {
      //   'dy-recommendation-context': ['innerHTML'],
      // } : undefined,
    };
  },
  mounted() {
    let dataRecContext = '';

    if (this.contextType === 'HOMEPAGE' || this.contextType === 'OTHER' || this.contextType === '') {
      dataRecContext = { type: this.contextType };
    } else {
      dataRecContext = { type: this.contextType, data: this.contextData };
    }

    window.DY = window.DY || {};
    window.DY.recommendationContext = dataRecContext;
  },
  render(h) {
    return h(); // Returning an empty vnode (like a comment)
  },
};
</script>
