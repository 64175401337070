import { defineStore } from 'pinia';

interface CategoryProductOptionState {
  result: null,
}

export const useCategoryproductoptionStore = defineStore('categoryproductoption', {
  state: (): CategoryProductOptionState => ({
    result: null,
  }),
});
