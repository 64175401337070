import { useRouter, useContext } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useCategoryproductoptionStore } from '~/modules/fortytwo/category/stores/categoryproductoption';

export const useAddToCart = () => {
  const {
    addItem: addItemToCartBase,
    isInCart,
    error,
  } = useCart();
  const router = useRouter();
  const { app } = useContext();
  const { getProductPath } = useProduct();
  const CategoryProductOption:any = useCategoryproductoptionStore();

  const addItemToCart = async (params: { product: Product, quantity: number, itmConfigurationSelected, canAddConfigItmToCart}) => {
    
    const { product, quantity, itmConfigurationSelected:productConfiguration, canAddConfigItmToCart } = params;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const productType = product.__typename;
    
    switch (productType) {
      case 'SimpleProduct':
        await addItemToCartBase({
          product,
          quantity,
        });
        break;
      case 'ConfigurableProduct':
        if(canAddConfigItmToCart) {
          await addItemToCartBase({
            product,
            quantity,
            productConfiguration
          });
          CategoryProductOption.$patch((state)=>{ state.result = null });// reset Pinia State
        }
        else {
          const path = app.localeRoute(getProductPath(product));
          await router.push(path);
        }
        break;
      case 'BundleProduct':
      case 'GroupedProduct':
        const path = app.localeRoute(getProductPath(product));

        await router.push(path);
        break;
      default:
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
  };

  return {
    addItemToCart,
    isInCart,
    error,
  };
};
